import React from "react";
import BackGround from "./BackGround";
import { Text, View, PanResponder } from "react-native";

export default class App extends React.Component {
  render() {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center"
        }}>
        <View
          style={{
            position: "absolute",
            flex: 1
          }}>
          <Text
            style={{
              color: "white",
              fontSize: 48
            }}>
            Welcome to Lantos.dev
          </Text>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            padding: 10
          }}>
          <Text
            style={{
              color: "white",
              fontFamily:
                "source-code-pro,Menlo,Monaco,Consolas,Courier New,monospace"
            }}>
            www.poseidonservers.net
          </Text>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            padding: 10
          }}>
          <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/520398585&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"}></iframe>
        </View>
        <BackGround />
      </View>
    );
  }
}
