import React from "react";
import { Text, View } from "react-native";
import { Surface, Shape, LinearGradient, Path } from "react-art";

export default class BackGround extends React.Component {
  constructor(props) {
    super(props);
    this.color = ["#4fb4e2", "#b490ca", "#b490ca"];
    this.state = {
      path: Path()
        .moveTo(0, 0)
        .lineTo(500, 0)
        .lineTo(500, 500)
        .lineTo(0, 500)
        .close(),
      linearGradient: new LinearGradient(this.color, 0, 0, 150, 150)
    };
    this.timer = setInterval(() => {
      const x = 250 + 150 * Math.sin(Date.now() / 1000);
      const y = 250 + 150 * Math.cos(Date.now() / 1000);
      this.setState({
        linearGradient: new LinearGradient(this.color, 0, 0, x, y)
      });
    }, 100);
  }
  render() {
    return (
      <Surface
        style={{
          width: "100%",
          height: "100%"
        }}>
        <Shape d={this.state.path} fill={this.state.linearGradient} />
      </Surface>
    );
  }
}
